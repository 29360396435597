import React from 'react';
import { useTranslation } from 'react-i18next';

import AutoUpdate from '../auto_update_widget';
import ChangeLanguageButton from '../change_language_button';
import './style.css';

export default function Header() {
  const { t } = useTranslation();
  return (
  	<header className={'header'}>
  		<h1>{t('misc.header')}</h1>
  		<nav>
        <AutoUpdate />
        <ChangeLanguageButton />
  		</nav>
  	</header>
  );
}
