import React, {useState} from 'react';
import { useTranslation } from 'react-i18next';

import ClockWidget from '../../widgets/clock_widget';
import WeatherWidget from '../../components/weather_widget';
import CalendarWidget from '../../widgets/calendar_widget';
import NotesWidget from '../../components/notes_widget';
import GoogleApi from '../../components/google_api';
import PhotosWidget from '../../components/photos_widget';

import './style.css';

export default function Home({googleApiLoaded}) {
  const { t } = useTranslation();
  const [signedIn, setSignedIn] = useState(false);

  const googleWidgets = (<>
    <CalendarWidget />
    <PhotosWidget />
    </>);

  return (<div className={'home'}>
    <WeatherWidget />
    <ClockWidget />
    { signedIn && googleWidgets }
    <div>
      <NotesWidget storageKey="DAYBOOK_NOTES" header={t('notes.title')} />
    </div>
    { googleApiLoaded && (<GoogleApi onSignedIn={setSignedIn} />) }
  </div>)
};
