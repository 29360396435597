import React from 'react';

import './style.css';

export default function PhotosWidgetAlbum({album, onClick}) {
  return (
    <div onClick={onClick} className={'album'}>
      <span>{ album.title }</span>
    </div>
  );
}
