import React from "react";
import { useTranslation } from "react-i18next";

import "./style.css";

export default function LoadingPart({ loading, noText }) {
  const { t } = useTranslation();

  return (
    <div className={"loading"} data-no-text={noText}>
      {t("misc.loading")}
    </div>
  );
}
