import React from 'react';
import { useTranslation } from 'react-i18next';
import CalendarEvent from './calendar-event';

export default function CalendarEventsGroup({events, title, onUpdate}) {
  const { t } = useTranslation();
  return (
    <div className="group">
      <h3 className="subtitle">{title}</h3>
      {
        events.map((item) => <CalendarEvent key={item.id} item={item} onUpdate={() => onUpdate(item)} /> )
      }
      { !events.length && (<div>{t('calendar.noPlans')}</div>) }
    </div>
  )
}
