import React, { useCallback, useMemo } from "react";
import UserStorage from "../user-storage";
/**
 * Mixin that allows to save state in persistent storage and restore it on load
 */

const OPTIONS = "_OPTIONS";

const withStorage = (WrappedComponent, prefix) => (props) => {
  const storage = useMemo(() => new UserStorage({ prefix }), []);

  const loadStorage = useCallback(() => {
    return storage.getItem(OPTIONS);
  }, [storage]);

  const saveStorage = useCallback(
    (data) => {
      return storage.setItem(OPTIONS, data);
    },
    [storage]
  );

  return (
    <WrappedComponent
      loadStorage={loadStorage}
      saveStorage={saveStorage}
      {...props}
    />
  );
};

export default withStorage;
