import { Component } from "react";

import "./style.css";

export class FormWidget extends Component {
  state = {};

  onFormUpdated(change) {
    this.setState(change, () => this.props.onChange(this.state));
  }

  componentDidMount() {
    console.log("w config", this.props);
    this.setState(this.props.initData);
  }
}
