import React from "react";
import { useTranslation } from "react-i18next";

export default function PhotosWidgetVideoLink({ link }) {
  const { t } = useTranslation();

  return (
    // just a hack to open the item in new tab
    // eslint-disable-next-line jsx-a11y/anchor-has-content
    <a
      href={link}
      target="_blank"
      className={"extLink"}
      title={t("photos.openInNewTab")}
      rel="noreferrer"
    ></a>
  );
}
