import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import "./style.css";

const VIDEO_ID = process.env.REACT_APP_DEMO_VIDEO_ID;

export default function DemoWidget() {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);

  const onClick = useCallback(() => {
    setShow(!show);
  }, [show]);

  return (
    <div className={"wrap"}>
      <div>
        <a href="#demoVideo" onClick={onClick}>
          {t("misc.demo")}
        </a>
      </div>

      {show && (
        <iframe
          id="demoVideo"
          title="Demo Video"
          width="560"
          height="315"
          src={"https://www.youtube.com/embed/" + VIDEO_ID + "?&autoplay=1"}
          frameborder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      )}
    </div>
  );
}
