import React from 'react';

export function CalendarItem({item}) {
  const inlineStyle = {
    backgroundColor: item.backgroundColor,
    color: item.foregroundColor
  };
  return (
    <div
      onClick={this.props.onSelect}
      style={inlineStyle}
      className={'calendarItem'}>{item.summary}</div>);
}
