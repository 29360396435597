import React, { useCallback, useState } from "react";

import "./style.css";

export default function CollapseWidget({ onClick }) {
  const [collapsed, setCollapsed] = useState(false);

  const onClickHandler = useCallback(() => {
    const newCollapsed = !collapsed;
    setCollapsed(newCollapsed);
    onClick(newCollapsed);
  }, [collapsed, onClick]);

  return (
    <span onClick={onClickHandler} className={"button"}>
      {collapsed ? "+" : "-"}
    </span>
  );
}
