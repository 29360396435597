import React from "react";
import moment from "moment";
import { CHECK_MARK } from "./constants";

export default function CalendarEvent({ item, onUpdate }) {
  const formatDate = (dateTime) => {
    if (dateTime === undefined) {
      return "";
    }
    const date = moment(dateTime);

    return date.format("H:mm [ - ]");
  };

  const getClass = () => {
    let result = "item";
    if (item.start.date) {
      result += " item_whole_day";
    }
    if (item.summary[0] === CHECK_MARK) {
      result += " item_done";
    }
    return result;
  };

  return (
    <div className={getClass()} onClick={onUpdate}>
      {formatDate(item.start.dateTime)}
      {item.summary}
    </div>
  );
}
