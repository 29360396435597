import React, {useCallback} from 'react';

import PhotosWidgetVideoLink from './video_link';
import PhotosWidgetVideo from './video';
import PhotosWidgetPhotoItem from './photo_item';
import './style.css';

const PHOTO_WIDTH = 1200;
const PHOTO_HEIGHT = 800;

export default function PhotosWidgetPhotos({photo, isIOS}) {
  const prepareData = useCallback((photo) => {
    console.log('prepareData', photo);
    let suffix = `=h${PHOTO_HEIGHT}`;
    let result = {
      imgUrl: photo.baseUrl + suffix,
      productUrl: photo.productUrl,
      videoUrl: null
    }
    if (photo.mediaMetadata && photo.mediaMetadata.video) {
      result.videoUrl = photo.baseUrl + '=dv';
    }
    return result;
  }, []);

  const newImg = prepareData(photo);

  return (
    <div className={'container'}>
      <div className={'photo_wrapper'}>
        { isIOS && newImg.videoUrl && (<PhotosWidgetVideoLink link={newImg.productUrl} />) }

        { newImg.videoUrl && !isIOS ? (<PhotosWidgetVideo src={newImg.videoUrl} img={newImg.imgUrl} />) : ''}

        { newImg.imgUrl && (<PhotosWidgetPhotoItem photo={newImg} isIOS={isIOS} width={PHOTO_WIDTH} height={PHOTO_HEIGHT} />) }
      </div>
    </div>
  );
}
