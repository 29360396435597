import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import styles from './style.module.css';
const lune = require('lune');

const REFRESH_INTERVAL = 1*60*60*1000;

class MoonWidget extends Component {
  constructor(props) {
    super(props);
    this.state = {
      age: 1,
      phase: 0,
      icon: 0
    }
    this.timer = null;
  }

  setPhase() {
    const moon = lune.phase();
    const phase = Math.floor(moon.phase * 100);
    const icon = this.phaseInBoundaries(phase);
    console.log('Moon', moon);
    this.setState({
      age: Math.floor(moon.age + 1),
      phase,
      icon
    });
  }

  phaseInBoundaries(phase) {
    const step = 101/8;
    const boundaries = [0, 1, 2, 3, 4, 5, 6, 7];
    return boundaries.find((border => {
      let normalisedBorder = border * step;
      return normalisedBorder <= phase && (border + 1) * step > phase;
    }));
  }

  componentDidMount() {
    this.setPhase();
    this.timer = setInterval(() => {this.setPhase()}, REFRESH_INTERVAL);
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  render() {
    const { t } = this.props;
    const {age, phase, icon} = this.state;
    return (
      <div className={styles.container}>
        <div className={styles.icon} data-icon={icon}></div>
        <div className={styles.head}>{t('moon.moon')}</div>
        <div className={styles.text}>{t('moon.age', {age})}, {t('moon.phase')} {phase}%</div>
      </div>
    )
  }

}

export default withTranslation()(MoonWidget);
