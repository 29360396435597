import React, {useState, useEffect, useMemo, useCallback} from 'react';
import moment from 'moment/moment';
import DateTime from 'luxon/src/datetime';

import './style.css';
import withStorage from '../../lib/hoc/withStorage';
import ClockConfig from './clock_config';
import { getCurrentLangCode } from '../../hooks/getCurrentLangCode';
import ConfigButtonWidget from '../../components/config_button_widget';
import { makeStyles } from '@material-ui/core';

const STORAGE_PREFIX = 'CLOCK_WIDGET';
const REFRESH_INTERVAL = 60 * 1000;

const useStyles = makeStyles({
  container: {
    background: 'transparent',
  },
  h1: {
    margin: 0,
    textAlign: 'center',
    fontSize: '3em',
    color: '#4e4e4e',
  },
  h1b: {
    fontWeight: 'normal',
    animation: '$blink-animation 1s steps(2, start) infinite',
  },
  day: {
    textTransform: 'capitalize',
    marginRight: '0.4em',
  },

  '@keyframes blink-animation': {
    '100%': {
      visibility: 'hidden',
    },
  }
});

function ClockWidget({loadStorage, saveStorage}) {
  const s = useStyles();
  const [time, setTime] = useState(moment());
  const [loaded, setLoaded] = useState(false);
  const [config, setConfig] = useState({
    showDay: false,
  });
  const [configVisible, setConfigVisible] = useState(false);

  const day = useMemo(() => {
    return config.showDay
      ? DateTime.now().setLocale(getCurrentLangCode()).toFormat('cccc')
      : null;
  }, [config.showDay]);

  const updateTime = () => {
    setTime(moment());
  };

  const updateConfig = useCallback(
    (updatedConfig) => {
      saveStorage(updatedConfig);
      setConfig(updatedConfig);
    },
    [saveStorage]
  );

  useEffect(() => {
    const timer = setInterval(updateTime, REFRESH_INTERVAL);

    if (!loaded) {
      const values = loadStorage();
      if (values) {
        console.log('clock config loaded', values);
        setConfig(values);
      }
      setLoaded(true);
    }

    return () => {
      clearInterval(timer);
    };
  }, [loadStorage, loaded]);

  return (
    <div className={s.container}>
      <ConfigButtonWidget onClick={() => setConfigVisible(!configVisible)} />

      {configVisible && (
        <ClockConfig config={config} updateConfig={updateConfig} />
      )}
      <h1 className={s.h1}>
        <span className={s.day}>{day}</span>
        {time.format('H')}
        <b className={s.h1b}>:</b>
        {time.format('mm')}
      </h1>
    </div>
  );
}

export default withStorage(ClockWidget, STORAGE_PREFIX);
