import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  wrapper: {
    marginTop: 40,
    padding: 20,
    textAlign: "center",
  },
  button: {
    padding: "10px 15",
    fontSize: 15,
    background: "rgba(80, 150, 255, 0.05)",
    borderRadius: 8,
    boxShadow: "none",
    border: "1px solid #ddd",
    color: "#666",
    textDecoration: "none",
  },
  buttonImg: {
    maxWidth: 18,
    float: "left",
    marginRight: 12,
  },
});

// Array of API discovery doc URLs for APIs used by the quickstart
const DISCOVERY_DOCS = [
  "https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest",
  "https://www.googleapis.com/discovery/v1/apis/photoslibrary/v1/rest",
];

// Authorization scopes required by the API; multiple scopes can be
// included, separated by spaces.
const SCOPES =
  "https://www.googleapis.com/auth/calendar.readonly https://www.googleapis.com/auth/calendar.events https://www.googleapis.com/auth/photoslibrary.readonly";
const CLIENT = process.env.REACT_APP_GOOGLE_CLIENT;
const KEY = process.env.REACT_APP_GOOGLE_API_KEY;

const updateSigninStatusRef = {
  cb: () => {},
};

/**
 *  On load, called to load the auth2 library and API client library.
 */
function handleClientLoad() {
  window.gapi.load("client:auth2", initClient);
}

/**
 *  Initializes the API client library and sets up sign-in state
 *  listeners.
 */
function initClient() {
  window.gapi.client
    .init({
      apiKey: KEY,
      clientId: CLIENT,
      discoveryDocs: DISCOVERY_DOCS,
      scope: SCOPES,
    })
    .then(
      () => {
        // Listen for sign-in state changes.
        window.gapi.auth2
          .getAuthInstance()
          .isSignedIn.listen(updateSigninStatusRef.cb);

        // Handle the initial sign-in state.
        updateSigninStatusRef.cb(
          window.gapi.auth2.getAuthInstance().isSignedIn.get()
        );
        // authorizeButton.onclick = handleAuthClick;
        // signoutButton.onclick = handleSignoutClick;
      },
      (error) => {
        console.error("GoogleApi", error);
      }
    );
}

/**
 *  Sign in the user upon button click.
 */
function handleAuthClick(event) {
  window.gapi.auth2.getAuthInstance().signIn();
}

/**
 *  Sign out the user upon button click.
 */
function handleSignoutClick(event) {
  window.gapi.auth2.getAuthInstance().signOut();
}

function GoogleApi({ onSignedIn }) {
  const [signedIn, setSignedIn] = useState(false);
  const { t } = useTranslation();
  const s = useStyles();

  useEffect(() => {
    handleClientLoad();
  }, []);

  /**
   *  Called when the signed in status changes, to update the UI
   *  appropriately. After a sign-in, the API is called.
   */
  updateSigninStatusRef.cb = (isSignedIn) => {
    setSignedIn(isSignedIn);
    onSignedIn(isSignedIn);
  };

  const onAuthorize = (e) => {
    handleAuthClick();
  };
  const onLogOut = (e) => {
    handleSignoutClick();
  };

  let buttonProps = {
    onClick: onLogOut,
    title: t("google.logout"),
  };
  if (!signedIn) {
    buttonProps = {
      onClick: onAuthorize,
      title: t("google.login"),
    };
  }
  const widgetContent = AuthButton(buttonProps);

  return <div className={s.wrapper}>{widgetContent}</div>;
}

export function AuthButton({ onClick, title }) {
  const s = useStyles();
  return (
    <button className={s.button} onClick={onClick}>
      <img
        alt="google icon"
        className={s.buttonImg}
        src="/assets/icons8-google.svg"
      />
      {` ${title}`}
    </button>
  );
}

export default GoogleApi;
