import React, {useCallback} from 'react';
import {Checkbox, FormControlLabel, FormGroup} from '@material-ui/core';
import {useTranslation} from 'react-i18next';

export default function ClockConfig({config = {}, updateConfig}) {
  const {t} = useTranslation();
  console.log('ClockConfig conf', config);
  const handleShowDayChange = useCallback(
    (event) => {
      updateConfig({
        ...config,
        showDay: event.target.checked,
      });
    },
    [config, updateConfig]
  );

  return (
    <FormGroup>
      <FormControlLabel
        control={
          <Checkbox
            checked={config.showDay}
            color="primary"
            onChange={handleShowDayChange}
            name="day"
          />
        }
        label={t('clock.showDay')}
      />
    </FormGroup>
  );
}
