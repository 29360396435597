import { makeStyles } from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles({
  wrapper: {
    display: "flex",
  },
  wrapperRight: {
    justifyContent: "flex-end",
  },
  wrapperLeft: {
    justifyContent: "flex-start",
  },
  button: {
    marginLeft: "10px",
    fontSize: "24px",
    fontWeight: "bold",
    cursor: "pointer",
  },
});

export default function ConfigButtonWidget({ position = "right", onClick }) {
  const s = useStyles();
  const posClass = position === "right" ? s.wrapperRight : s.wrapperLeft;
  return (
    <div className={clsx(s.wrapper, posClass)}>
      <span onClick={onClick} className={s.button}>
        ⚙︎
      </span>
    </div>
  );
}
