import React, { useEffect, useCallback, useState } from "react";
import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";

import Header from "./components/header";
import Home from "./routes/home";
import Footer from "./components/footer";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

export default function App() {
  const [googleApiLoaded, setGoogleApiLoaded] = useState(false);
  const history = createBrowserHistory();

  const loadExternals = useCallback(() => {
    const script = document.createElement("script");
    script.async = true;
    script.defer = true;
    script.src = "https://apis.google.com/js/api.js";
    script.onload = () => setGoogleApiLoaded(true);
    script.onreadystatechange =
      "if (this.readyState === 'complete') this.onload()";

    document.body.appendChild(script);
  }, [setGoogleApiLoaded]);

  useEffect(() => {
    loadExternals();
  }, [loadExternals]);

  return (
    <div id="app">
      <Header />
      <Router history={history}>
        <DndProvider backend={HTML5Backend}>
          <Home path="/" googleApiLoaded={googleApiLoaded} />
        </DndProvider>
      </Router>
      <Footer />
    </div>
  );
}
