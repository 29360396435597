import React from "react";
import { withTranslation } from "react-i18next";

import { FormWidget } from "../form_widget";

const FormStyle = {
  wrapper: {},
  formLine: {},
  buttonNoBorder: {},
  button: {},
};

class WeatherConfigWidget extends FormWidget {
  state = {
    units: "C",
    location: "",
  };

  geoFindMe() {
    const success = (position) => {
      const latitude = position.coords.latitude;
      const longitude = position.coords.longitude;

      console.log("GeoLocation success", latitude, longitude);

      this.onFormUpdated({
        location: {
          lon: longitude,
          lat: latitude,
        },
      });
    };

    const error = (err) => {
      console.error("GeoLocation error", err);
    };

    if (!navigator.geolocation) {
      console.log("Geolocation is not supported by your browser");
    } else {
      navigator.geolocation.getCurrentPosition(success, error);
    }
  }

  render() {
    const { t } = this.props;
    const { units, location } = this.state;
    let locationString = location;
    const geolocationSupported = navigator.geolocation;
    if (typeof location === "object") {
      locationString = JSON.stringify(location);
    }
    return (
      <div className={FormStyle.wrapper}>
        <div className={FormStyle.formLine}>
          <label>{t("weather.location")}</label>
          <input
            type="text"
            value={locationString}
            onChange={(e) => this.onFormUpdated({ location: e.target.value })}
          />
          {geolocationSupported && (
            <button
              className={FormStyle.buttonNoBorder}
              onClick={(e) => this.geoFindMe()}
            >
              {t("weather.geolocation")}
            </button>
          )}
        </div>

        <div className={FormStyle.formLine}>
          <label>{t("weather.select_units")}</label>
          <select
            onChange={(e) => this.onFormUpdated({ units: e.target.value })}
          >
            <option selected={units === "C"} value="C">
              Celcius
            </option>
            <option selected={units === "F"} value="F">
              Farenheit
            </option>
          </select>
        </div>
      </div>
    );
  }
}

export default withTranslation()(WeatherConfigWidget);
