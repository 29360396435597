import React, { Component } from "react";
import { withTranslation } from "react-i18next";

import StorageMixin from "../../lib/storage-mixin";
import i18n from "../../lib/i18n";

class ChangeLanguageButton extends Component {
  state = {
    lang: "ru",
  };

  constructor(props) {
    super(props);

    Object.assign(this, new StorageMixin("StengazetaLanguage"));
  }

  changeLanguage(lang) {
    if (!lang) {
      lang = i18n.language === "en" ? "ru" : "en";
    }
    i18n.changeLanguage(lang, (err, t) => {
      if (err) {
        return console.error("Change Language", err);
      }
      this.saveState({ lang });
    });
  }

  componentDidMount() {
    this.loadState(["lang"], () => {
      console.log("ChangeLanguageButton restored", this.state);
      if (this.state.lang) {
        this.changeLanguage(this.state.lang);
      }
    });
  }

  render() {
    const { t } = this.props;
    return (
      <span onClick={() => this.changeLanguage()}>{t("lang.button")}</span>
    );
  }
}

export default withTranslation()(ChangeLanguageButton);
