import React, { useEffect, useState, useCallback } from "react";
import { makeStyles } from "@material-ui/core";

import withStorage from "../../lib/hoc/withStorage";
import { DAYBOOK_NOTES } from "./constants";

const useStyles = makeStyles({
  textarea: {
    width: "100%",
    minHeight: "10em",
    fontSize: 24,
    boxShadow: "inset 0 0 10px -1px rgba(0,0,0,0.1)",
    padding: "10px 15px",
  },
});

function NotesWidget({ header, loadStorage, saveStorage }) {
  const s = useStyles();
  const [text, setText] = useState("");

  const onChange = useCallback(
    (event) => {
      let val = event.target.value;
      setText(val);
      saveStorage({ text: val });
    },
    [saveStorage]
  );

  useEffect(() => {
    const stored = loadStorage();
    if (stored?.text) {
      setText(stored.text);
    }
  }, [loadStorage]);

  return (
    <div>
      <h3>{header}</h3>
      <div>
        <textarea onChange={onChange} className={s.textarea} value={text} />
      </div>
    </div>
  );
}

export default withStorage(NotesWidget, DAYBOOK_NOTES);
