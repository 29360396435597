import React, { Component } from "react";
import { withTranslation } from "react-i18next";

import "./style.css";

const API_INTERVAL = 60 * 60 * 1000;

class AutoUpdate extends Component {
  timer = null;
  xhr = null;
  attr = "data-build-id";

  state = {
    version: process.env.REACT_APP_BUILD_ID,
    newVersion: false,
  };

  check() {
    this.xhr = new XMLHttpRequest();
    this.xhr.onload = this.onResult.bind(this);
    this.xhr.onerror = () => {
      console.error("AutoUpdate Error while getting XML.");
      this.timer = setTimeout(() => this.check(), API_INTERVAL);
    };
    this.xhr.open("GET", window.location.href);
    this.xhr.responseType = "document";
    this.xhr.send();
  }

  onResult() {
    let newVersion = this.compareVersions();
    if (newVersion) {
      console.log("AutoUpdate new version");
    } else {
      console.log("AutoUpdate old version");
      this.timer = setTimeout(() => this.check(), API_INTERVAL);
    }
  }

  getVersion() {
    let element = this.xhr.responseXML.documentElement.querySelector(
      "[" + this.attr + "]"
    );
    if (!element) {
      return null;
    }
    let theAttr = element.attributes[this.attr];
    if (!theAttr) {
      return null;
    }
    return theAttr.value;
  }

  getOldVersion() {
    return process.env.REACT_APP_BUILD_ID;
  }

  compareVersions() {
    let newVersion = this.getVersion();
    console.log("AutoUpdate got version", newVersion);
    if (newVersion && newVersion !== this.state.version) {
      this.setState({
        version: newVersion,
        newVersion: true,
      });
      return true;
    } else {
      return false;
    }
  }

  componentDidMount() {
    this.check();
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  onClick() {
    window.location.href = "/?v=" + this.state.version;
  }

  render() {
    const { t } = this.props;
    const { newVersion } = this.state;
    const updateButton = newVersion && (
      <span onClick={() => this.onClick()}>{t("update.newVersion")}</span>
    );
    return <span>{updateButton}</span>;
  }
}

export default withTranslation()(AutoUpdate);
